<template>
  <div class="products category-med category-last">
    <Breadcrumb :items="breadcrumb" />
    <v-container class="py-0">
      <category-block
        :target="category"
        position="position1"
        class="category-block category-block-1"
      />

      <CategoryTitle :category="category" :count="count" />
    </v-container>
    <v-container>
      <ProductListGrid
        v-bind:parentCategoryId="category.categoryId"
        :key="category.categoryId"
        @productsCount="updateCount"
        hideFilterString="Main Term"
        itemListName="Category Last"
      />
    </v-container>
    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />
    <category-block
      :target="category"
      position="position3"
      class="category-block category-block-3"
    />

    <v-container>
      <ebsn-meta
        :target="category"
        path="category_info.FOOTER_DESCRIPTION"
        tag="div"
      />
    </v-container>
  </div>
</template>
<script>
import ProductListGrid from "@/components/product/ProductListGrid.vue";
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
// import CategoryBlock from "@/components/categoryBlock/CategoryBlock.vue";

import categoryMixins from "~/mixins/category";
import get from "lodash/get";

export default {
  name: "CategoryLast",
  mixins: [categoryMixins],
  components: {
    ProductListGrid,
    CategoryTitle,
    Breadcrumb
    // ,
    // CategoryBlock
  },
  data() {
    return { count: -1 };
  },
  methods: {
    updateCount(count) {
      this.count = count;
    }
  },
  metaInfo() {
    return {
      title: get(
        this.category,
        "metaData.category_seo.SEO_TITLE",
        this.category ? this.category.name : ""
      ),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: get(
            this.category,
            "metaData.category_seo.SEO_DESCRIPTION",
            this.$t("meta.category.secondAndThirdLevel.description", [
              this.category.name,
              this.breadcrumb[this.breadcrumb.length - 2]?.text
            ])
          )
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: get(this.category, "metaData.category_seo.SEO_KEYWORDS")
        },
        // Open Graph Metas
        {
          property: "og:locale",
          content: "it_IT"
        },
        {
          property: "og:title",
          content: get(
            this.category,
            "metaData.category_seo.SEO_TITLE",
            this.category ? this.category.name : ""
          )
        },
        {
          property: "og:type",
          content: "product.group"
        },
        {
          property: "og:url",
          content: location.href
        },
        {
          property: "og:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          property: "og:image:alt",
          content: get(
            this.category,
            "metaData.category_seo.SEO_DESCRIPTION",
            this.$t("meta.category.secondAndThirdLevel.description", [
              this.category.name,
              this.breadcrumb[this.breadcrumb.length - 2]?.text
            ])
          )
        },
        {
          property: "og:site_name",
          content: "Demo"
        },
        // Twitter Metas
        {
          name: "twitter:card",
          content: "summary_large_image"
        },
        {
          name: "twitter:title",
          content: get(
            this.category,
            "metaData.category_seo.SEO_TITLE",
            this.category ? this.category.name : ""
          )
        },
        {
          name: "twitter:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          name: "twitter:url",
          content: location.href
        }
      ]
    };
  }
};
</script>
